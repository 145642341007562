// tslint:disable
/**
 * Reservation Management
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as globalImportUrl from 'url';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';

import { GetPropertyOccupancyListResponse } from '../models';
import { ProblemDetails } from '../models';
/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {boolean} [excludeFreeRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportOccupancyExportGet(
      from: string,
      to: string,
      excludeFreeRooms?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError(
          'from',
          'Required parameter from was null or undefined when calling reportOccupancyExportGet.'
        );
      }
      // verify required parameter 'to' is not null or undefined
      if (to === null || to === undefined) {
        throw new RequiredError(
          'to',
          'Required parameter to was null or undefined when calling reportOccupancyExportGet.'
        );
      }
      const localVarPath = `/report/occupancy/export`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      if (excludeFreeRooms !== undefined) {
        localVarQueryParameter['excludeFreeRooms'] = excludeFreeRooms;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} from
     * @param {string} to
     * @param {boolean} [excludeFreeRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportOccupancyGet(
      propertyId: string,
      from: string,
      to: string,
      excludeFreeRooms?: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling reportOccupancyGet.'
        );
      }
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError(
          'from',
          'Required parameter from was null or undefined when calling reportOccupancyGet.'
        );
      }
      // verify required parameter 'to' is not null or undefined
      if (to === null || to === undefined) {
        throw new RequiredError(
          'to',
          'Required parameter to was null or undefined when calling reportOccupancyGet.'
        );
      }
      const localVarPath = `/report/occupancy`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      if (excludeFreeRooms !== undefined) {
        localVarQueryParameter['excludeFreeRooms'] = excludeFreeRooms;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} from
     * @param {string} to
     * @param {boolean} excludeRetroactiveTax
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportTaxesEmailReportGet(
      propertyId: string,
      from: string,
      to: string,
      excludeRetroactiveTax: boolean,
      options: any = {}
    ): RequestArgs {
      // verify required parameter 'propertyId' is not null or undefined
      if (propertyId === null || propertyId === undefined) {
        throw new RequiredError(
          'propertyId',
          'Required parameter propertyId was null or undefined when calling reportTaxesEmailReportGet.'
        );
      }
      // verify required parameter 'from' is not null or undefined
      if (from === null || from === undefined) {
        throw new RequiredError(
          'from',
          'Required parameter from was null or undefined when calling reportTaxesEmailReportGet.'
        );
      }
      // verify required parameter 'to' is not null or undefined
      if (to === null || to === undefined) {
        throw new RequiredError(
          'to',
          'Required parameter to was null or undefined when calling reportTaxesEmailReportGet.'
        );
      }
      // verify required parameter 'excludeRetroactiveTax' is not null or undefined
      if (excludeRetroactiveTax === null || excludeRetroactiveTax === undefined) {
        throw new RequiredError(
          'excludeRetroactiveTax',
          'Required parameter excludeRetroactiveTax was null or undefined when calling reportTaxesEmailReportGet.'
        );
      }
      const localVarPath = `/report/taxes/email-report`;
      const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }
      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (propertyId !== undefined) {
        localVarQueryParameter['propertyId'] = propertyId;
      }

      if (from !== undefined) {
        localVarQueryParameter['from'] = from;
      }

      if (to !== undefined) {
        localVarQueryParameter['to'] = to;
      }

      if (excludeRetroactiveTax !== undefined) {
        localVarQueryParameter['excludeRetroactiveTax'] = excludeRetroactiveTax;
      }

      localVarUrlObj.query = {
        ...localVarUrlObj.query,
        ...localVarQueryParameter,
        ...options.query,
      };
      // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
      delete localVarUrlObj.search;
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: globalImportUrl.format(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {boolean} [excludeFreeRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportOccupancyExportGet(
      from: string,
      to: string,
      excludeFreeRooms?: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<any> {
      const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).reportOccupancyExportGet(
        from,
        to,
        excludeFreeRooms,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} from
     * @param {string} to
     * @param {boolean} [excludeFreeRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportOccupancyGet(
      propertyId: string,
      from: string,
      to: string,
      excludeFreeRooms?: boolean,
      options?: any
    ): (
      axios?: AxiosInstance,
      basePath?: string
    ) => AxiosPromise<GetPropertyOccupancyListResponse> {
      const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).reportOccupancyGet(
        propertyId,
        from,
        to,
        excludeFreeRooms,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} from
     * @param {string} to
     * @param {boolean} excludeRetroactiveTax
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportTaxesEmailReportGet(
      propertyId: string,
      from: string,
      to: string,
      excludeRetroactiveTax: boolean,
      options?: any
    ): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<void> {
      const localVarAxiosArgs = ReportApiAxiosParamCreator(configuration).reportTaxesEmailReportGet(
        propertyId,
        from,
        to,
        excludeRetroactiveTax,
        options
      );
      return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
        const axiosRequestArgs = {
          ...localVarAxiosArgs.options,
          url: basePath + localVarAxiosArgs.url,
        };
        return axios.request(axiosRequestArgs);
      };
    },
  };
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  return {
    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {boolean} [excludeFreeRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportOccupancyExportGet(
      from: string,
      to: string,
      excludeFreeRooms?: boolean,
      options?: any
    ): AxiosPromise<any> {
      return ReportApiFp(configuration).reportOccupancyExportGet(
        from,
        to,
        excludeFreeRooms,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} from
     * @param {string} to
     * @param {boolean} [excludeFreeRooms]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportOccupancyGet(
      propertyId: string,
      from: string,
      to: string,
      excludeFreeRooms?: boolean,
      options?: any
    ): AxiosPromise<GetPropertyOccupancyListResponse> {
      return ReportApiFp(configuration).reportOccupancyGet(
        propertyId,
        from,
        to,
        excludeFreeRooms,
        options
      )(axios, basePath);
    },
    /**
     *
     * @param {string} propertyId
     * @param {string} from
     * @param {string} to
     * @param {boolean} excludeRetroactiveTax
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    reportTaxesEmailReportGet(
      propertyId: string,
      from: string,
      to: string,
      excludeRetroactiveTax: boolean,
      options?: any
    ): AxiosPromise<void> {
      return ReportApiFp(configuration).reportTaxesEmailReportGet(
        propertyId,
        from,
        to,
        excludeRetroactiveTax,
        options
      )(axios, basePath);
    },
  };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
  /**
   *
   * @param {string} from
   * @param {string} to
   * @param {boolean} [excludeFreeRooms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportOccupancyExportGet(
    from: string,
    to: string,
    excludeFreeRooms?: boolean,
    options?: any
  ) {
    return ReportApiFp(this.configuration).reportOccupancyExportGet(
      from,
      to,
      excludeFreeRooms,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} from
   * @param {string} to
   * @param {boolean} [excludeFreeRooms]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportOccupancyGet(
    propertyId: string,
    from: string,
    to: string,
    excludeFreeRooms?: boolean,
    options?: any
  ) {
    return ReportApiFp(this.configuration).reportOccupancyGet(
      propertyId,
      from,
      to,
      excludeFreeRooms,
      options
    )(this.axios, this.basePath);
  }

  /**
   *
   * @param {string} propertyId
   * @param {string} from
   * @param {string} to
   * @param {boolean} excludeRetroactiveTax
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ReportApi
   */
  public reportTaxesEmailReportGet(
    propertyId: string,
    from: string,
    to: string,
    excludeRetroactiveTax: boolean,
    options?: any
  ) {
    return ReportApiFp(this.configuration).reportTaxesEmailReportGet(
      propertyId,
      from,
      to,
      excludeRetroactiveTax,
      options
    )(this.axios, this.basePath);
  }
}
