import { from } from 'rxjs';
import { map } from 'rxjs/operators';
import { ReportStore, reportStore } from './report.store';
import {
  Properties,
  Reservations,
  PointOfSale,
  PaginationState,
  exportFileName,
  dispatchCancel,
  dispatchForm,
  CommonForm,
  MediaTypes,
} from '@lib/state';
import { lightFormat } from 'date-fns';
import { applyTransaction, setLoading } from '@datorama/akita';
import { appendData, ReportType } from './report.model';

export class ReportService {
  constructor(
    private readonly store: ReportStore,
    private readonly propertiesReportApi: Properties.ReportApi,
    private readonly reservationReportApi: Reservations.ReportApi,
    private readonly posReportApi: PointOfSale.ReportApi
  ) {}

  private queryLimit = 10;

  loadInHouseGuests(propertyId: string, date: Date, corporateAccountName?: string): void {
    const d = date.toISOString();
    from(
      this.propertiesReportApi.reportPropertyIdInHouseGet(
        propertyId,
        d,
        corporateAccountName || undefined
      )
    )
      .pipe(
        setLoading(this.store),
        map(response => response.data.data),
        dispatchCancel(this.store)
      )
      .subscribe(data => {
        this.store.update({
          report: {
            type: 'InHouseGuestReport',
            data: data,
          },
        });
      });
  }

  loadRoomOccupancy(propertyId: string, start: Date, end: Date, excludeNotCharge: boolean): void {
    let startDate = lightFormat(start, 'yyyy-MM-dd');
    let endDate = lightFormat(end, 'yyyy-MM-dd');

    from(
      this.reservationReportApi.reportOccupancyGet(propertyId, startDate, endDate, excludeNotCharge)
    )
      .pipe(
        setLoading(this.store),
        map(response => response.data.data),
        dispatchCancel(this.store)
      )
      .subscribe(data => {
        this.store.update({
          report: {
            type: 'OccupancyReport',
            data: data,
          },
        });
      });
  }

  loadPurchaseTransactions(
    propertyId: string,
    start: Date,
    end: Date,
    purchaseType?: PointOfSale.PurchaseType,
    continuationToken?: string | null,
    limit?: number
  ): void {
    let startDate = lightFormat(start, 'yyyy-MM-dd');
    let endDate = lightFormat(end, 'yyyy-MM-dd');

    if (!continuationToken) this.store.reset();

    from(
      this.posReportApi.reportPropertyIdPurchasesGet(
        propertyId,
        continuationToken ?? undefined,
        limit ?? this.queryLimit,
        startDate,
        endDate,
        purchaseType
      )
    )
      .pipe(
        setLoading(this.store),
        map(response => response.data),
        dispatchCancel(this.store)
      )
      .subscribe(({ data, ...pagination }) => {
        applyTransaction(() => {
          this.store.update(x => ({
            report: appendData(x.report, 'PurchaseTransactionReport', data) as ReportType,
          }));
          this.updatePaginationState(pagination);
        });
      });
  }

  exportTransactions(
    propertyId: string,
    startDate: Date,
    endDate: Date,
    purchaseType?: PointOfSale.PurchaseType
  ) {
    from(
      this.posReportApi.reportPropertyIdPurchasesExportGet(
        propertyId,
        lightFormat(startDate, 'yyyy-MM-dd'),
        lightFormat(endDate, 'yyyy-MM-dd'),
        purchaseType
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: 'text/csv' })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, exportFileName(startDate, endDate)));
  }

  private updatePaginationState({ isDone, continuationToken }: PaginationState) {
    this.store.update(() => ({
      pagination: { isDone, continuationToken },
    }));
  }

  exportTaxCreditReport(
    propertyId: string,
    startDate: Date,
    endDate: Date,
    excludeRetroactiveTax: boolean
  ) {
    from(
      this.reservationReportApi.reportTaxesEmailReportGet(
        propertyId,
        lightFormat(startDate, 'yyyy-MM-dd'),
        lightFormat(endDate, 'yyyy-MM-dd'),
        excludeRetroactiveTax
      )
    )
      .pipe(dispatchForm(CommonForm.Export))
      .subscribe();
  }

  exportDailyOccupancyReport(
    startDate: Date,
    endDate: Date,
    excludeNotCharge: boolean,
    filename: string
  ) {
    from(
      this.reservationReportApi.reportOccupancyExportGet(
        lightFormat(startDate, 'yyyy-MM-dd'),
        lightFormat(endDate, 'yyyy-MM-dd'),
        excludeNotCharge,
        {
          responseType: 'arraybuffer', // Pass 'arraybuffer' as responseType in options
        }
      )
    )
      .pipe(
        map(x => new Blob([x.data], { type: MediaTypes.ExcelFile })),
        dispatchForm(CommonForm.Export)
      )
      .subscribe(x => saveAs(x, filename));
  }

  reset() {
    this.store.reset();
  }
}

export const reportService = new ReportService(
  reportStore,
  new Properties.ReportApi(),
  new Reservations.ReportApi(),
  new PointOfSale.ReportApi()
);
