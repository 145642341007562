import { RoomType } from '@lib/state';
import { OccupancyType } from '@lib/state/api/generated/properties';
import { RoomRatePlan } from 'app/state';

export function DSOFeeColumn(ratePlanRoomTypes: RoomType[]) {
  //if none of the rate plans are double occupancy, don't add column.
  if (!ratePlanRoomTypes.some(t => t.occupancyType === OccupancyType.Double)) {
    return [];
  }

  return [
    {
      title: 'DSO Fee',
      valueFactory: (plan: RoomRatePlan) =>
        !plan.dsoFee || plan.dsoFee === 0 ? '--' : plan.dsoFee,
    },
  ];
}
